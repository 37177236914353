import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { customerPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const identityPermissions = customerPermissionKeys.identity;

export const usersMenu: MenuElement = {
  translation: 'MENU.USERS.TITLE',
  icon: IconName.profile,
  id: 'menu-users',
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users/new`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users/:userId/details`,
      ],
      translation: 'MENU.USERS.ITEMS.LIST',
      permissionsConfiguration: {
        permissions: [
          { key: identityPermissions.viewUsers, source: AccessSource.Tenant },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+;',
      keyboardShortcutMac: '⌥+⌘+;',
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/roles`,
      translation: 'MENU.USERS.ITEMS.ROLES',
      permissionsConfiguration: {
        permissions: [
          { key: identityPermissions.viewRoles, source: AccessSource.Tenant },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
