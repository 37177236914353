import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isMac } from 'frontend-container/utils/isMac';

import { useSharedTooltipPresenter } from '@ac/react-infrastructure';
import {
  IconName,
  MenuButtonSize,
  Size,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  onClickCallback: () => void;
}

export const GlobalSearchMenuIcon = ({
  onClickCallback,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { createShowHandler } = useSharedTooltipPresenter();

  const text = useMemo(() => {
    return `${t('MENU.GLOBAL_SEARCH.SEARCH')} ${isMac ? '⌘K' : 'ctrl+k'}`;
  }, [t]);

  return (
    <ac-menu-button
      size={MenuButtonSize.trigger}
      transparent
      id="menu-global-search-icon"
      onClick={onClickCallback}
      onMouseEnter={createShowHandler({
        text,
        targetValue: TargetValueObject.mainMenu,
      })}
    >
      <ac-icon icon={IconName.search} size={Size.lg} />
    </ac-menu-button>
  );
};
