import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  propertyPermissionKeys,
  RatesPropertySettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const rateManagerPermissions = propertyPermissionKeys.rateManager;

export const ratesMenu: MenuElement = {
  translation: 'MENU.RATES.TITLE',
  icon: IconName.rateManager,
  id: 'menu-rates',
  items: [
    {
      link: `${acConfig.newFrontendUrls.rateManager}/grid`,
      aliases: [acConfig.newFrontendUrls.rateManager],
      code: 'RateManager',
      translation: 'MENU.RATES.ITEMS.RATE_MANAGER',
      permissionsConfiguration: {
        permissions: [
          {
            key: rateManagerPermissions.rateManager.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+0',
      keyboardShortcutMac: '⌥+⌘+0',
      keyCode: 48,
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/restrictions-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: rateManagerPermissions.restrictionsDashboard.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      code: 'Restrictions',
      translation: 'MENU.RATES.ITEMS.RESTRICTIONS',
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/hurdle-table`,
      settings: [
        {
          key: RatesPropertySettingsKeys.HurdleRatesCurrency,
          source: AccessSource.Property,
        },
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: rateManagerPermissions.hurdleTable.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      code: 'HurdleTable',
      translation: 'MENU.RATES.ITEMS.HURDLE_TABLE',
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/day-type-periods-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: rateManagerPermissions.dayTypePeriodsDashboard.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      code: 'DayTypePeriods',
      translation: 'MENU.RATES.ITEMS.DAY_TYPE_PERIODS',
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/exchange-rates`,
      permissionsConfiguration: {
        permissions: [
          {
            key: rateManagerPermissions.exchangeRates.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      code: 'ExchangeRates',
      translation: 'MENU.RATES.ITEMS.EXCHANGE_RATES',
    },
    {
      link: `${acConfig.newFrontendUrls.distributionChannelManager}/dashboard`,
      featureToggles: [
        {
          key: FeatureToggleName.ProductDistributionManager,
          source: AccessSource.Property,
        },
      ],
      code: 'DistributionChannelManager',
      translation: 'MENU.RATES.ITEMS.DISTRIBUTION_CHANNEL_MANAGER',
    },
  ],
};
