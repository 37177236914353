import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  HousekeepingCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const housekeepingPermissions = propertyPermissionKeys.housekeeping;

export const housekeepingDashboardLink = `${acConfig.newFrontendUrls.housekeeping}/dashboard`;

export const housekeepingMenu: MenuElement = {
  translation: 'MENU.HOUSEKEEPING.TITLE',
  icon: IconName.housekeeping,
  id: 'menu-housekeeping',
  settings: [
    {
      key: HousekeepingCustomerSettingsKeys.UseHousekeepingFunctionality,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: housekeepingDashboardLink,
      aliases: [acConfig.newFrontendUrls.housekeeping],
      code: 'HousekeepingDashboard',
      translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.rooms.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+k',
      keyboardShortcutMac: '⌥+⌘+k',
      keyCode: 75,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/attendant-assignments`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ATTENDANT_ASSIGNMENT',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+d',
      keyboardShortcutMac: '⌥+⌘+d',
      keyCode: 68,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/attendant-assignments-v2`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ATTENDANT_ASSIGNMENT_V2',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.HousekeepingShifts,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/maintenance-dashboard`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD_MAINTENANCE',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.maintenance.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+o',
      keyboardShortcutMac: '⌥+⌘+o',
      keyCode: 79,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/room-history`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ROOM_HISTORY',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.roomsHistory.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+y',
      keyboardShortcutMac: '⌥+⌘+y',
      keyCode: 89,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/printed-sheets-history`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.PRINTED_SHEETS_HISTORY',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
