import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  ProfilesCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const commissionsMenu: MenuElement = {
  translation: 'MENU.COMMISSIONS.TITLE',
  icon: IconName.commission,
  id: 'menu-commissions',
  settings: [
    {
      key: ProfilesCustomerSettingsKeys.TravelAgentCommissions,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.travelAgentCommissions}/dashboard`,
      aliases: [acConfig.newFrontendUrls.travelAgentCommissions],
      code: 'CommissionDashboard',
      translation: 'MENU.COMMISSIONS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.travelAgentCommissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: 'alt+ctrl+z',
      keyboardShortcutMac: '⌥+⌘+z',
      keyCode: 90,
    },
  ],
};
