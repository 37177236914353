import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchNotificationCount } from 'frontend-container/components/Menu/components/NotificationBell/notificationCountFetcher';
import { NotificationPanel } from 'frontend-container/components/Menu/components/NotificationPanel/NotificationPanel';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import { isNotificationBellVisible } from 'frontend-container/components/Menu/utils/modules/notifications';
import { NotificationsNames } from 'frontend-container/shared/webSockets/types';
import debounce from 'lodash.debounce';

import { NotificationChannel } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import {
  useSharedTooltipPresenter,
  useTenantWebSocketClientsContext,
} from '@ac/react-infrastructure';
import {
  ButtonPattern,
  ButtonTheme,
  Components,
  CounterTheme,
  IconName,
  MenuButtonSize,
  Size,
  TargetValueObject,
} from '@ac/web-components';

export const isBellVisible = (): boolean =>
  LoginService.isLoggedIn() &&
  !LoginService.isSuperUser() &&
  userService.getCurrentUserDetails() &&
  isNotificationBellVisible();

export const NotificationBell = (): JSX.Element => {
  const { t } = useTranslation();
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [isPanelVisible, setPanelVisibility] = useState<boolean>(false);

  const {
    state: { webSockets },
  } = useTenantWebSocketClientsContext();

  const isPropertyContext = !isPathnameWithoutProperty();

  const { createShowHandler } = useSharedTooltipPresenter({
    targetValue: TargetValueObject.mainMenu,
  });

  useEffect(() => {
    const notificationChannels: NotificationChannel[] = [];

    (async (): Promise<void> => {
      notificationChannels.push(
        await webSockets.user.subscribe(
          { notificationType: NotificationsNames.UserNotificationCreated },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          { notificationType: NotificationsNames.UserNotificationMarkedAsRead },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          {
            notificationType: NotificationsNames.UserNotificationMarkedAsUnread,
          },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          {
            notificationType: NotificationsNames.UserNotificationsMarkedAsRead,
          },
          debounced
        )
      );
    })();

    return (): void => {
      notificationChannels.forEach((channel: NotificationChannel) => {
        channel.unsubscribe();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => updateNumberOfNotifications())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPropertyContext]);

  const toggleNotificationsPanel = (): void =>
    setPanelVisibility(!isPanelVisible);

  const updateNumberOfNotifications = async (): Promise<void> => {
    const notificationCount = await fetchNotificationCount();
    setNotificationsCount(notificationCount.unreadCount);
  };

  const debounced = debounce(updateNumberOfNotifications, 500);

  const counter: Components.AcCounter = {
    count: notificationsCount,
    theme: CounterTheme.dark,
  };

  const closeNotificationsPanel = useCallback((): void => {
    setPanelVisibility(false);
  }, []);

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <ac-flex
      class={`${
        !isMenuV2Enabled ? 'ac-spacing-horizontal-sm' : ''
      } force-direction-ltr`}
    >
      <ac-counter-container counter={counter}>
        {isMenuV2Enabled ? (
          <>
            <ac-menu-button
              size={MenuButtonSize.trigger}
              transparent
              id="notification-bell-button"
              onMouseEnter={createShowHandler({
                text: t('MENU.NOTIFICATIONS.TOOLTIP'),
              })}
              onClick={toggleNotificationsPanel}
            >
              <ac-icon icon={IconName.bell} size={Size.lg} />
            </ac-menu-button>
          </>
        ) : (
          <ac-button
            data-test-selector="notification-bell"
            theme={!getIsMenuV2Enabled() ? ButtonTheme.light : undefined}
            pattern={ButtonPattern.tertiary}
            onClick={toggleNotificationsPanel}
          >
            <ac-icon icon={IconName.bell} />
          </ac-button>
        )}
      </ac-counter-container>
      <NotificationPanel
        isPanelVisible={isPanelVisible}
        isPropertyContext={isPropertyContext}
        unreadNotificationsCount={notificationsCount}
        onPanelClose={closeNotificationsPanel}
      />
    </ac-flex>
  );
};
