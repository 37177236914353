import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { FeatureToggleName, propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const cashieringDashboardPermissions =
  propertyPermissionKeys.cashiering.dashboard;
const cashieringAccountPermissions = propertyPermissionKeys.cashiering.account;
const cashieringBillingPermissions = propertyPermissionKeys.cashiering.billing;
const cashieringBillingsPermissions =
  propertyPermissionKeys.cashiering.billings;
const cashierPermissions = propertyPermissionKeys.cashier;
const foreignCurrencyExchangePermissions =
  propertyPermissionKeys.foreignCurrencyExchange;

export const cashieringDashboardLink = `${acConfig.newFrontendUrls.cashiering}/dashboard`;

export const cashieringMenu: MenuElement = {
  translation: 'MENU.CASHIERING.TITLE',
  icon: IconName.cashiering,
  id: 'menu-cashiering',
  items: [
    {
      link: cashieringDashboardLink,
      aliases: [
        `${acConfig.newFrontendUrls.cashiering}/selected-accounts/summary`,
        `${acConfig.newFrontendUrls.cashiering}/cashier-closure`,
        `${acConfig.newFrontendUrls.reservations}/cashiering/billing`,
        `${acConfig.newFrontendUrls.reservations}/cashiering/accounts`,
      ],
      code: 'CashierDashboard',
      translation: 'MENU.CASHIERING.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringDashboardPermissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+c',
      keyboardShortcutMac: '⌥+⌘+c',
      keyCode: 67,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/quick-posting`,
      translation: 'MENU.CASHIERING.ITEMS.QUICK_POSTING',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingPermissions.viewQuickPosting,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutMac: '⌥+⌘+3',
      keyboardShortcutWindows: 'alt+ctrl+3',
      keyCode: 51,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/policy/deposits`,
      translation: 'MENU.CASHIERING.ITEMS.POLICY_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringDashboardPermissions.viewPolicyDepositsDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      aliases: [
        `${acConfig.newFrontendUrls.cashiering}/policy/deposits/summary`,
      ],
      keyboardShortcutWindows: 'alt+ctrl+4',
      keyboardShortcutMac: '⌥+⌘+4',
      keyCode: 52,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/complimentary-rules`,
      translation: 'MENU.CASHIERING.ITEMS.COMPLIMENTARY_RULES',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingsPermissions.manageComplimentaryRule,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.ComplimentaryAccounting,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/posting-journal`,
      translation: 'MENU.CASHIERING.ITEMS.POSTING_JOURNAL',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringBillingPermissions.postingJournal,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+j',
      keyboardShortcutMac: '⌥+⌘+j',
      keyCode: 74,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/number-list-view`,
      translation: 'MENU.CASHIERING.ITEMS.CASHIER_STATUS',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashierPermissions.viewCashierStatusDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+u',
      keyboardShortcutMac: '⌥+⌘+u',
      keyCode: 85,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/cashiering`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/cashiering/accounts/new`,
      ],
      translation: 'MENU.CASHIERING.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [
          {
            key: cashieringAccountPermissions.manageAccountSetup,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/dashboard?foreignCurrencyExchange=true`,
      translation: 'MENU.CASHIERING.ITEMS.CURRENCY_EXCHANGE',
      permissionsConfiguration: {
        permissions: [
          {
            key: foreignCurrencyExchangePermissions.foreignCurrencyExchange,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+[',
      keyboardShortcutMac: '⌥+⌘+[',
      keyCode: 219,
    },
  ],
};
