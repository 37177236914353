import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  GeneralPropertySettingsKeys,
  propertyPermissionKeys,
  ReservationsCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
export const frontDeskDashboardLink = `${acConfig.newFrontendUrls.reservations}/frontdesk`;
export const houseStatusLink = `${acConfig.newFrontendUrls.reports}/house-status-dashboard`;
const housekeepingPermissions = propertyPermissionKeys.housekeeping;

export const frontdeskMenu: MenuElement = {
  translation: 'MENU.FRONTDESK.TITLE',
  icon: IconName.frontdesk,
  id: 'menu-frontdesk',
  items: [
    {
      link: frontDeskDashboardLink,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/:id/check-in`,
      ],
      code: 'FrontDeskDashboard',
      translation: 'MENU.FRONTDESK.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+f',
      keyboardShortcutMac: '⌥+⌘+f',
      keyCode: 70,
    },
    {
      link: houseStatusLink,
      code: 'HouseStatus',
      translation: 'MENU.FRONTDESK.ITEMS.HOUSE_STATUS_DASHBOARD',
      keyboardShortcutWindows: 'alt+ctrl+s',
      keyboardShortcutMac: '⌥+⌘+s',
      keyCode: 83,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/room-planner`,
      code: 'RoomPlanner',
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_PLANNER',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.IsRoomPlannerActive,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: 'alt+ctrl+l',
      keyboardShortcutMac: '⌥+⌘+l',
      keyCode: 76,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/rooms/assignment`,
      code: 'RoomAssignment',
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_ASSIGNMENT',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.RoomAssignment,
          source: AccessSource.Property,
        },
      ],
      featureToggles: [
        {
          key: FeatureToggleName.RoomAssignmentDashboard,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: 'alt+ctrl+]',
      keyboardShortcutMac: '⌥+⌘+]',
      keyCode: 221,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/walk-in`,
      translation: 'MENU.FRONTDESK.ITEMS.WALK_IN',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
          {
            key: reservationPermissions.reservation.checkInReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+/',
      keyboardShortcutMac: '⌥+⌘+/',
      keyCode: 191,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/queue-rooms`,
      translation: 'MENU.FRONTDESK.ITEMS.QUEUE_ROOMS',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.queueRooms.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.queueRooms,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/floor-plan`,
      translation: 'MENU.FRONTDESK.ITEMS.FLOOR_PLAN',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.rooms.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: GeneralPropertySettingsKeys.FloorPlan,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/guest-messages`,
      translation: 'MENU.FRONTDESK.ITEMS.GUEST_MESSAGES',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.guestMessages.accessMessageCenter,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.GuestMessage,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: 'alt+ctrl+m',
      keyboardShortcutMac: '⌥+⌘+m',
      keyCode: 77,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/wake-up-calls`,
      translation: 'MENU.FRONTDESK.ITEMS.WAKE_UP_CALLS',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.WakeUpCalls,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: 'alt+ctrl+w',
      keyboardShortcutMac: '⌥+⌘+w',
      keyCode: 87,
    },
  ],
};
