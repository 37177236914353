import { useMemo, useState } from 'react';
import { GLOBAL_SEARCH_FAVORITES_STORAGE_KEY } from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export interface FavoriteItems {
  favoriteItems: GlobalSearchMenuElementItem[];
  favoritesItemsIds: string[];
  setFavoriteItems: (favoriteIds: string[]) => void;
  toggleFavorite: (
    isFav: boolean,
    menuItem: GlobalSearchMenuElementItem,
    favIds: string[],
    onFavoriteClickHandler: (favoriteIds: string[]) => void
  ) => void;
}

export const useFavoriteItems = ({
  globalSearchItems,
}: Props): FavoriteItems => {
  const getLocalStorageFavoriteIds = (): string[] =>
    LocalStorage.getParsed<string>(GLOBAL_SEARCH_FAVORITES_STORAGE_KEY)?.split(
      ','
    ) ?? [];

  const [favoritesItemsIds, setFavoritesItemsIds] = useState<string[]>(
    getLocalStorageFavoriteIds
  );

  const favoriteItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!favoritesItemsIds.length) {
      return [];
    }

    return globalSearchItems
      .filter((allowedItem) => favoritesItemsIds.includes(allowedItem.id))
      .map((el) => ({ ...el, adjustedId: `${el.id}-favorite` }));
  }, [globalSearchItems, favoritesItemsIds]);

  const setFavoriteItems = (favoriteIds: string[]): void => {
    setFavoritesItemsIds(favoriteIds);
    LocalStorage.set(
      GLOBAL_SEARCH_FAVORITES_STORAGE_KEY,
      favoriteIds.join(',')
    );
  };

  const toggleFavorite = (
    isFav: boolean,
    menuItem: GlobalSearchMenuElementItem,
    favIds: string[],
    onFavoriteClickHandler: (favoriteIds: string[]) => void
  ): void => {
    if (isFav) {
      onFavoriteClickHandler([menuItem.id, ...favIds]);
    } else {
      const itemIds = favIds.filter((favId) => favId !== menuItem.id);
      onFavoriteClickHandler(itemIds);
    }
  };

  return { favoritesItemsIds, favoriteItems, setFavoriteItems, toggleFavorite };
};
