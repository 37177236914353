import { useTranslation } from 'react-i18next';
import { GlobalSearchModalListHeader } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalListHeader';
import { GlobalSearchModalListItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalListItem';
import { useFavoriteItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useFavoriteItems';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { hasOnlyNumbers } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/hasOnlyNumbers';

import { DividerColor, DividerSpacing } from '@ac/web-components';

interface Props {
  searchValue: string | undefined;
  highlightedItem: string | undefined;
  favoriteItems: GlobalSearchMenuElementItem[];
  popularItems: GlobalSearchMenuElementItem[];
  filteredMenuItems: GlobalSearchMenuElementItem[];
  searchForOptions: GlobalSearchMenuElementItem[];
  searchForNumberOptions: GlobalSearchMenuElementItem[];
  favoritesItemsIds: string[];
  recentItems: GlobalSearchMenuElementItem[];
  onFavoriteClickHandler: (favoriteIds: string[]) => void;
  onClickHandler: (
    selectedItem: GlobalSearchMenuElementItem,
    openInNewTab?: boolean
  ) => void;
}

export const GlobalSearchModalList = ({
  highlightedItem,
  searchValue,
  favoriteItems,
  filteredMenuItems,
  favoritesItemsIds,
  popularItems,
  recentItems,
  searchForOptions,
  searchForNumberOptions,
  onClickHandler,
  onFavoriteClickHandler,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const { toggleFavorite } = useFavoriteItems({ globalSearchItems: [] });

  const searchOnlyNumbers = hasOnlyNumbers(searchValue);

  return (
    <ac-box class="global-search-list">
      {filteredMenuItems?.map((menuItem, index) => (
        <GlobalSearchModalListItem
          key={index}
          item={menuItem}
          highlightedText={searchValue}
          onClickCallback={(): void => onClickHandler(menuItem)}
          isFavourite={favoritesItemsIds.includes(menuItem.id)}
          isHighlighted={menuItem.adjustedId === highlightedItem}
          onFavoriteClickCallback={(isFav): void =>
            toggleFavorite(
              isFav,
              menuItem,
              favoritesItemsIds,
              onFavoriteClickHandler
            )
          }
        />
      ))}

      {!!filteredMenuItems.length && (
        <ac-box class="ac-spacing-horizontal-md">
          <ac-divider
            spacing={DividerSpacing.md}
            color={DividerColor.gray200}
          />
        </ac-box>
      )}

      {searchValue && (
        <GlobalSearchModalListHeader
          text={t('MENU.GLOBAL_SEARCH.SEARCH_FOR.SEARCH_FOR')}
        />
      )}
      {searchOnlyNumbers &&
        searchForNumberOptions.map((searchItem) => (
          <GlobalSearchModalListItem
            key={searchItem.id}
            item={searchItem}
            highlightedText={searchValue}
            isHighlighted={searchItem.adjustedId === highlightedItem}
            onClickCallback={(): void => onClickHandler(searchItem)}
          />
        ))}

      {searchOnlyNumbers && (
        <ac-box class="ac-spacing-horizontal-md">
          <ac-divider
            spacing={DividerSpacing.md}
            color={DividerColor.gray200}
          />
        </ac-box>
      )}

      {searchValue &&
        searchForOptions.map((searchItem) => (
          <GlobalSearchModalListItem
            key={searchItem.id}
            item={searchItem}
            highlightedText={searchValue}
            isHighlighted={searchItem.adjustedId === highlightedItem}
            onClickCallback={(): void => onClickHandler(searchItem)}
          />
        ))}

      {!searchValue &&
        !!recentItems?.length && [
          <GlobalSearchModalListHeader
            key="global-search-recent-key"
            text={t('MENU.GLOBAL_SEARCH.RECENT')}
          />,
          ...recentItems.map((recentItem) => (
            <GlobalSearchModalListItem
              key={recentItem.id}
              item={recentItem}
              highlightedText={searchValue}
              onClickCallback={(): void => onClickHandler(recentItem)}
              isFavourite={favoritesItemsIds.includes(recentItem.id)}
              isHighlighted={recentItem.adjustedId === highlightedItem}
              onFavoriteClickCallback={(isFav): void =>
                toggleFavorite(
                  isFav,
                  recentItem,
                  favoritesItemsIds,
                  onFavoriteClickHandler
                )
              }
            />
          )),
        ]}

      {!searchValue && !!(recentItems?.length && favoriteItems?.length) && (
        <ac-box class="ac-spacing-horizontal-md">
          <ac-divider
            spacing={DividerSpacing.md}
            color={DividerColor.gray200}
          />
        </ac-box>
      )}

      {!searchValue &&
        !!favoriteItems?.length && [
          <GlobalSearchModalListHeader
            key="global-search-favorite-key"
            text={t('MENU.GLOBAL_SEARCH.FAVORITES')}
          />,
          ...favoriteItems.map((favoriteItem) => (
            <GlobalSearchModalListItem
              key={favoriteItem.id}
              item={favoriteItem}
              highlightedText={searchValue}
              onClickCallback={(): void => onClickHandler(favoriteItem)}
              isFavourite={favoritesItemsIds.includes(favoriteItem.id)}
              isHighlighted={favoriteItem.adjustedId === highlightedItem}
              onFavoriteClickCallback={(isFav): void =>
                toggleFavorite(
                  isFav,
                  favoriteItem,
                  favoritesItemsIds,
                  onFavoriteClickHandler
                )
              }
            />
          )),
        ]}

      {!searchValue && !!(recentItems?.length || favoriteItems?.length) && (
        <ac-box class="ac-spacing-horizontal-md">
          <ac-divider
            spacing={DividerSpacing.md}
            color={DividerColor.gray200}
          />
        </ac-box>
      )}

      {!searchValue &&
        !!popularItems?.length && [
          <GlobalSearchModalListHeader
            key="global-search-popular-key"
            text={t('MENU.GLOBAL_SEARCH.POPULAR')}
          />,
          ...popularItems.map((popularItem) => (
            <GlobalSearchModalListItem
              key={popularItem.adjustedId}
              item={popularItem}
              highlightedText={searchValue}
              isFavourite={favoritesItemsIds.includes(popularItem.id)}
              isHighlighted={popularItem.adjustedId === highlightedItem}
              onClickCallback={(): void => onClickHandler(popularItem)}
              onFavoriteClickCallback={(isFav): void =>
                toggleFavorite(
                  isFav,
                  popularItem,
                  favoritesItemsIds,
                  onFavoriteClickHandler
                )
              }
            />
          )),
        ]}
    </ac-box>
  );
};
