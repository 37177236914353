import { FC, ReactNode, useEffect, useState } from 'react';
import { Context } from 'frontend-container/components/Menu/components/Context/context';
import { ContextMapper } from 'frontend-container/components/Menu/components/Context/contextMapper';
import {
  mapContextsToOptions,
  mapMenuItemsToOptions,
  mapTabsToOptions,
} from 'frontend-container/components/Menu/components/Context/mapListsToOptions';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  handleOnChange,
  isContextList,
} from 'frontend-container/components/Menu/utils/listOnChangeHandler';
import { getCurrentUnitFromBusinessContext } from 'frontend-container/shared/businessContext/getCurrentUnit';

import {
  AcSelectOption,
  Placement,
  SelectableListSlot,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  list: Context[] | ButtonContextOption | undefined;
  selectedContext: Context | undefined;
  isVisible?: boolean;
  children: ReactNode;
  onSelect: (
    selected: AcSelectOption[] | Context | object[] | undefined
  ) => void;
  onClose: () => void;
  buttonSelectOptions?: ButtonContextOption[];
  searchableListLength?: number;
  triggerId?: string;
  activeTab?: string | undefined;
}

export const GenericList: FC<Props> = ({
  list,
  isVisible,
  selectedContext,
  children,
  onSelect,
  onClose,
  buttonSelectOptions,
  searchableListLength = 7,
  triggerId,
  activeTab,
}) => {
  const onClick = (context: Context | undefined): void => {
    onSelect(context);
  };

  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    if (activeTab) {
      setSearchValue(undefined);
    }
  }, [activeTab]);

  const contextType = isContextList(list);

  const listLength = contextType
    ? list.length
    : list?.subMenuElements?.length ?? 0;
  const onChange = contextType ? handleOnChange(list, onClick) : undefined;

  const searchable = listLength > searchableListLength;

  const selectedValues = Object.values({
    ...getCurrentUnitFromBusinessContext(),
    ...(selectedContext
      ? ContextMapper.contextToBusinessContextUnitIdentifier(selectedContext)
      : {}),
  });

  const contextListProps = {
    searchable,
    optionSelectedField: 'value',
    value: selectedValues,
    onChangeCallback: onChange,
  };

  const nonContextListProps = {
    searchable: false,
    optionSelectedField: 'id',
    value: [`${Math.random()}`], // new value will disable selected item
    selectedItemsSection: false,
    onChangeCallback: onSelect,
  };

  const currentListProps = contextType ? contextListProps : nonContextListProps;

  const target = triggerId ?? '#context-list-wrapper';

  const optionsArray = contextType
    ? mapContextsToOptions(list)
    : [
        ...mapMenuItemsToOptions(list, selectedContext),
        ...mapTabsToOptions(buttonSelectOptions),
      ];

  return (
    <>
      <div id="context-list-wrapper">
        <ac-selectable-list
          minOptionsWidth={{ listContainer: 400, optionsWrapper: 400 }}
          attachTo={target}
          boundaryContainer="body"
          autoFilter
          isVisible={isVisible}
          placement={Placement.bottomEnd}
          target={target}
          targetValue={TargetValueObject.mainMenu}
          optionNameField="name"
          optionValueField="value"
          dropdownClass="selectable-list-dropdown-wrapper"
          onCloseCallback={onClose}
          optionsArray={optionsArray}
          searchValue={searchValue}
          onInputCallback={(value): void => setSearchValue(value)}
          {...currentListProps}
        >
          <div slot={SelectableListSlot.listHeader} className="list-header">
            {children}
          </div>
        </ac-selectable-list>
      </div>
    </>
  );
};
