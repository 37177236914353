import { getLocalizedDescriptionFromLocalizedStrings } from 'frontend-container/utils/language/getLocalizedDescriptionFromLocalizedStrings';

import {
  BusinessContextUnitIdentifier,
  CentralReservationOffice,
  CentralReservationOfficeUserListItemDto,
  ProfileCenterDto,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { IconName } from '@ac/web-components';

import { Context, ContextType } from './context';

const unitToContext = (item: UnitAssignmentListItem): Context => ({
  id: item.unitId,
  code: item.code,
  name: item.name,
  regionCode: item.regionCode,
  type: ContextType.PROPERTY,
  icon: IconName.configProperty,
});

const croToContext = (
  item: CentralReservationOffice | CentralReservationOfficeUserListItemDto
): Context => ({
  id: item.id,
  code: item.code ?? '',
  name: item.name ?? '',
  regionCode: item.regionCode ?? '',
  type: ContextType.CRO,
  icon: IconName.cro,
});

const profileCenterToContext = (item: ProfileCenterDto): Context => ({
  id: item.id,
  code: item.code,
  name: getLocalizedDescriptionFromLocalizedStrings(item.description),
  regionCode: '',
  type: ContextType.PROFILE_CENTER,
  icon: IconName.profileDetails,
});

const contextToBusinessContextUnitIdentifier = (
  context: Context
): BusinessContextUnitIdentifier => {
  switch (context.type) {
    case ContextType.CRO:
      return { centralReservationOfficeId: context.id };
    case ContextType.PROFILE_CENTER:
      return { profileCenterId: context.id };
    default:
      return { propertyId: context.id };
  }
};

export const ContextMapper = {
  unitToContext,
  croToContext,
  profileCenterToContext,
  contextToBusinessContextUnitIdentifier,
};
