import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  MeetingsAndEventsCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const meetingAndEventsPermissions = propertyPermissionKeys.meetingsAndEvents;

export const meetingsAndEventsMenu: MenuElement = {
  translation: 'MENU.MEETINGS_AND_EVENTS.TITLE',
  icon: IconName.conferences,
  id: 'menu-meetings-and-events',
  permissionsConfiguration: {
    permissions: [
      {
        key: meetingAndEventsPermissions.appAccess,
        source: AccessSource.Property,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  settings: [
    {
      key: MeetingsAndEventsCustomerSettingsKeys.MeetingsAndEventsModuleActivation,
      source: AccessSource.Property,
    },
  ],
  featureToggles: [
    {
      key: FeatureToggleName.MeetingsAndEventsModule,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/booking-dashboard`,
      code: 'MeetingAndEventsBookingDashboard',
      aliases: [
        acConfig.newFrontendUrls.meetingsAndEvents,
        `${acConfig.newFrontendUrls.meetingsAndEvents}/booking`,
        `${acConfig.newFrontendUrls.itinerary}/integrated-booking/booking`,
      ],
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.BOOKINGS_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.booking.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+b',
      keyboardShortcutMac: '⌥+⌘+b',
      keyCode: 66,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/events-dashboard`,
      code: 'MeetingAndEventsEventDashboard',
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.EVENTS_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+n',
      keyboardShortcutMac: '⌥+⌘+n',
      keyCode: 78,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/conference-diary`,
      code: 'ConferenceDiary',
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.CONFERENCE_DIARY',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+8',
      keyboardShortcutMac: '⌥+⌘+8',
      keyCode: 56,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/booking/create`,
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.BOOKING_NEW',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.booking.create,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/waitlist-dashboard`,
      code: 'WaitlistDashboard',
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.WAITLIST_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.WaitlistPriority,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/function-space-rate-manager`,
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.FUNCTION_SPACE_RATE_MANAGER',
      keyboardShortcutWindows: 'alt+ctrl+9',
      keyboardShortcutMac: '⌥+⌘+9',
      keyCode: 57,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/packages`,
      settings: [
        {
          key: MeetingsAndEventsCustomerSettingsKeys.Packages,
          source: AccessSource.Property,
        },
      ],
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.PACKAGES',
      keyboardShortcutWindows: 'alt+ctrl+7',
      keyboardShortcutMac: '⌥+⌘+7',
      keyCode: 55,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/date-range-function-sheet-dashboard`,
      translation:
        'MENU.MEETINGS_AND_EVENTS.ITEMS.DATE_RANGE_FUNCTION_SHEET_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
